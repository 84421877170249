/* eslint-disable prefer-destructuring */
import React from 'react';
import {Button, Col, Row} from 'antd';
import {CSVLink} from "react-csv";
import ReactTable from 'react-table';
import './ReactTable2.css';
import PropTypes from 'prop-types';

class ResultsTable extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        resultSet: PropTypes.array,
    };

    constructor(props){
        super(props);
        this.download = this.download.bind(this);
        this.state = {
            dataToDownload: []
        }
    }

    download(event) {
        const columns = this.getColumns();
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        var data_to_download = [];
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {};
            for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
            }
            data_to_download.push(record_to_download)

        }
        this.setState({ dataToDownload: data_to_download }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.link.click()
        })

    }

    formattedDate(){
        const today = new Date();
        const yr = today.getFullYear();
        const mth = today.getMonth()+1;
        const dy = today.getDay();

        return `${yr}-${mth}-${dy}`;

    }

    getColumns() {
        if (
            this.props.resultSet === undefined ||
            this.props.resultSet.length === 0
        ) {
            return [
                {
                    Header: this.props.title,
                    minWidth: 400,

                },
            ];
        }

        const firstColumn = [
            {
                Header: 'ICHRIF Id',
                accessor: 'ichirfId',
            },
            {
                Header: 'Status',
                accessor: 'Status',
            },
            {
                Header: 'YoB',
                accessor: 'YoB',
            },
            {
                Header: 'MF',
                accessor: 'MF',
            },
        ];

        const keyColumns = this.props.keyColumns
            .map(key => ({
                Header: key.replace(/_/g,' '),
                accessor: key,
                minWidth: 90,
                Cell: row => (
                    <span style={{color: row.value != null && typeof row.value === 'string' && row.value.toLowerCase() === 'abnormal' ? '#ff2e00' : 'black' }}>
                            {row.value}
                        </span>

                )

            }));

        const columns = Object.keys(this.props.resultSet[0])
            .filter((key) => {
                const keycols = this.props.keyColumns;
                return !['ichirfId','ehrId','MF','Status','YoB','partyId',...keycols].includes(key);
            })
            .map(key => ({
                Header: key.replace(/_/g,' '),
                accessor: key,
                minWidth: 90,
                Cell: row => (
                    <span style={{color: row.value != null && typeof row.value === 'string' && row.value.toLowerCase() === 'abnormal' ? '#ff2e00' : 'black' }}>
                            {row.value}
                        </span>

                )

            }));



        return [...firstColumn, ...keyColumns, ...columns];

    }

    render() {

        if (!this.props.resultSet) {
            return (<div> </div> );
        }

        const columns = this.getColumns();
        const tableStyle =  { fontSize: '11px', height: "500px"};

        return (
            <div>
            <Row gutter={4} style={ {marginTop: 8} }>
                <Col span={6}>
                    <Button onClick={this.download}  icon="download">
                        Download to CSV
                    </Button>
                    <CSVLink  ref={(r) => this.csvLink = r} data={this.state.dataToDownload} filename= {`${this.props.title} ${this.formattedDate()}.csv`} className="hidden" target="_blank"/>
                </Col>
                <Col span={18}>
                    <h3>{this.props.title}</h3>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ReactTable
                        ref={(r) => this.reactTable = r}
                        className="-striped -highlight"
                        data={this.props.resultSet}
                        columns={columns}
                        minRows={20}
                        style = {tableStyle}
                        defaultPageSize={100}
                        defaultSorted={[{
                            id   : 'Status',
                            desc : false,
                        }]}
                    />
                </Col>
            </Row>
            </div>
        );
    }
}

export default ResultsTable;
