import React, { Component } from 'react';
import {Col, Row } from 'antd';
import TemplateList from "../../Lists/TemplateList/TemplateList";

class TemplatesPage extends Component{

    render() {
         return (
            <div className="TemplatesPage">
                    <h2>Templates</h2>
                    <Row>
                        <Col span={24}>
                            <TemplateList cdrName={'ichirf'} />
                        </Col>;
                    </Row>
            </div>
        );
    }
}

export default TemplatesPage;
