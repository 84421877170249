import React from 'react';
import ReactDOM from 'react-dom';
import  { BrowserRouter }  from 'react-router-dom'
import { AuthProvider } from './services/AuthContext/AuthContext';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(

    <AuthProvider cdrName='ichirf'>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </AuthProvider>,

    document.getElementById('root'));

registerServiceWorker();
