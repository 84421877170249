const aql = {
    imaging: {
        title: 'Imaging',
        nextColumns: ['MRI_basic_result'],
        select: `select e/ehr_id/value as ehrId, e/ehr_status/subject/external_ref/id/value as partyId,
         a_b/items[at0001]/items[at0002]/value/value as Acute_intracranial_collections_result,
         a_b/items[at0001]/items[at0006]/value/value as Chronic_intracranial_collections_result,
         a_b/items[at0001]/items[at0010]/value/value as Significant_mass_effect_result,
         a_b/items[at0014]/items[at0015]/value/value as Frontal_left_cortical_contusions,
         a_b/items[at0014]/items[at0019]/value/value as Frontal_right_cortical_contusions,
         a_b/items[at0014]/items[at0023]/value/value as Temporal_left_cortical_contusions,
         a_b/items[at0014]/items[at0027]/value/value as Temporal_right_cortical_contusions,
         a_b/items[at0014]/items[at0031]/value/value as Parietal_or_occipital_left_cortical_contusions,
         a_b/items[at0014]/items[at0035]/value/value as Parietal_or_occipital_right_cortical_contusions,
         a_b/items[at0043]/items[at0044]/value/value as Deep_parenchymal_haemorrhage, 
         a_b/items[at0043]/items[at0048]/value/value as Frontal_left_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0052]/value/value as Frontal_right_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0056]/value/value as Temporal_left_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0060]/value/value as Temporal_right_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0064]/value/value as Parietal_or_occipital_left_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0068]/value/value as Parietal_or_occipital_right_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0072]/value/value as Basal_ganglia_parenchymal_haemorrhage,
         a_b/items[at0043]/items[at0076]/value/value as Cerebellum_parenchymal_haemorrhage,
         a_b/items[at0089]/items[at0090]/value/value as Frontal_left_brain_volume_loss,
         a_b/items[at0089]/items[at0094]/value/value as Frontal_right_brain_volume_loss,
         a_b/items[at0089]/items[at0098]/value/value as Temporal_left_brain_volume_loss,
         a_b/items[at0089]/items[at0102]/value/value as Temporal_right_brain_volume_loss,
         a_b/items[at0089]/items[at0106]/value/value as Parietal_or_occipital_left_brain_volume_loss,
         a_b/items[at0089]/items[at0110]/value/value as Parietal_or_occipital_right_brain_volume_loss,
         a_b/items[at0089]/items[at0114]/value/value as Cerebellum_brain_volume_loss,
         a_b/items[at0126]/value/value as MRI_basic_result,
         a_b/items[at0130]/value/value as MRI_functional_result,
         a_b/items[at0088]/value/value as Overall_impression, 
         a_b/items[at0084]/value/value as Evidence_of_traumatic_brain_injury,
         a_b/items[at0039]/value/value as Evidence_of_DAI, a_b/items[at0080]/value/value as Superficial_siderosis,
         a_b/items[at0118]/value/value as Hydrocephalus, a_b/items[at0122]/value/value as Cavum_septum_pellucidum 
         from EHR e contains COMPOSITION a contains ( OBSERVATION a_a[openEHR-EHR-OBSERVATION.imaging_exam.v0] and CLUSTER a_b[openEHR-EHR-CLUSTER.ichirf_mri_details.v0]) `,
        where: `where a_b/items[at0001]/items[at0002]/value/value='Yes' or a_b/items[at0001]/items[at0006]/value/value='Yes' or a_b/items[at0001]/items[at0010]/value/value='Yes' or a_b/items[at0014]/items[at0015]/value/value='Yes' or a_b/items[at0014]/items[at0019]/value/value='Yes' or a_b/items[at0014]/items[at0023]/value/value='Yes' or a_b/items[at0014]/items[at0027]/value/value='Yes' or a_b/items[at0014]/items[at0031]/value/value='Yes' or a_b/items[at0014]/items[at0035]/value/value='Yes' or a_b/items[at0043]/items[at0044]/value/value='Yes' or a_b/items[at0043]/items[at0048]/value/value='Yes' or a_b/items[at0043]/items[at0052]/value/value='Yes' or a_b/items[at0043]/items[at0056]/value/value='Yes' or a_b/items[at0043]/items[at0060]/value/value='Yes' or a_b/items[at0043]/items[at0064]/value/value='Yes' or a_b/items[at0043]/items[at0068]/value/value='Yes' or a_b/items[at0043]/items[at0072]/value/value='Yes' or a_b/items[at0043]/items[at0076]/value/value='Yes' or a_b/items[at0089]/items[at0090]/value/value='Yes' or a_b/items[at0089]/items[at0094]/value/value='Yes' or a_b/items[at0089]/items[at0098]/value/value='Yes' or a_b/items[at0089]/items[at0102]/value/value='Yes' or a_b/items[at0089]/items[at0106]/value/value='Yes' or a_b/items[at0089]/items[at0110]/value/value='Yes' or a_b/items[at0089]/items[at0114]/value/value='Yes' or a_b/items[at0126]/value/value='Abnormal' or a_b/items[at0130]/value/value='Abnormal' or a_b/items[at0088]/value/value='Abnormal' or a_b/items[at0084]/value/value='Yes' or a_b/items[at0039]/value/value='Yes' or a_b/items[at0080]/value/value='Yes' or a_b/items[at0118]/value/value='Yes' or a_b/items[at0122]/value/value='Yes'`,

    },
    neurology: {

        title: 'Neurology',
        nextColumns: ['Summary_Interpretation'],
        select: `select 
            e/ehr_id/value as ehrId,
            e/ehr_status/subject/external_ref/id/value as partyId,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0009]/items[at0004]/value/value as Romberg,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0012]/items[at0013]/value/value as Ten_m_timed_walk,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0012]/items[at0015]/value/magnitude as Time,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0020]/value/value as Gait,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0030]/value/value as Smell,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0075]/value/magnitude as Score,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0024]/items[at0025]/value/value as Cranial_nerves_I,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0034]/items[at0035]/value/value as Cranial_nerves_II,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0039]/items[at0040]/value/value as Cranial_Nerves_III_IV_VI,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0044]/items[at0045]/value/value as Cranial_nerves_V_to_XII,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0049]/items[at0050]/value/value as tone_power_coordination,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0054]/items[at0055]/value/value as Reflexes,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0059]/items[at0060]/value/value as Plantar_responses,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0064]/items[at0065]/value/value as Sensation,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0069]/items[at0070]/value/value as Summary_Interpretation,
            neuro/data[at0001]/events[at0002]/data[at0003]/items[at0069]/items[at0073]/value/value as Notes
            
            from EHR e contains COMPOSITION a CONTAINS
                OBSERVATION neuro[openEHR-EHR-OBSERVATION.neurology_ichirf.v0] `,

        where: `where     
               neuro/data[at0001]/events[at0002]/data[at0003]/items[at0009]/items[at0004]/value/value='Abnormal'
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0012]/items[at0013]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0020]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0030]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0024]/items[at0025]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0034]/items[at0035]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0039]/items[at0040]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0044]/items[at0045]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0049]/items[at0050]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0054]/items[at0055]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0059]/items[at0060]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0064]/items[at0065]/value/value='Abnormal' 
        or     neuro/data[at0001]/events[at0002]/data[at0003]/items[at0069]/items[at0070]/value/value='Abnormal'`
        ,
    },
    exam: {

        title: 'Neuro exam',
        nextColumns: ['Summary_Interpretation'],
        select: `select
    e/ehr_id/value as ehrId,
    e/ehr_status/subject/external_ref/id/value as partyId,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0001]/value/value as Gait2,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0005]/value/value as Tandem_gait_forward_and_back,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0009]/value/value as Tandem_gait_with_head_turn,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0013]/value/value as Romberg2,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0017]/value/value as Romberg_with_neck_extension,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.bsit.v0]/items[at0001]/value/value as BSIT,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.bsit.v0]/items[at0005]/value/magnitude as Total_score,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0001]/value/value as PERL,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0005]/value/value as Fundi,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0009]/value/value as Smooth_pursuit,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0013]/value/value as Horizontal_saccades,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0017]/value/value as Vertical_saccades,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0001]/value/value as Weber,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0005]/value/value as Rinne,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0009]/value/value as Dix_Hallpike,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0013]/value/value as Vestibo_ocular_reflex,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0017]/value/value as Head_shake,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0021]/value/value as Head_thrust,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0025]/value/value as CN_5_to_12,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0001]/value/value as Long_tracts_upper_limb,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0005]/value/value as Long_tracts_lower_limb,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0009]/value/value as Coordination,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0013]/value/value as Cerebellar,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0001]/value/value as Extrapyramidal_signs,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0005]/value/value as Postural_reflexes,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0009]/value/value as Primitive_reflexes,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0013]/value/value as Luria_3_step_right_hand,
    a/content[openEHR-EHR-SECTION.adhoc.v1, 'Q9 Neurological examination']/items[openEHR-EHR-OBSERVATION.exam.v1, 'Physical examination findings #1']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0017]/value/value as Luria_3_step_left_hand
    from EHR e
    contains COMPOSITION a[openEHR-EHR-COMPOSITION.report.v1] contains OBSERVATION exam[openEHR-EHR-OBSERVATION.exam.v1] 
    where (a/name/value matches {'ICHIRF Registry Neurology Report'}) `,
        where: ` and (            
                exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0001]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0005]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0009]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0013]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_gait_and_balance.v0]/items[at0017]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.bsit.v0]/items[at0001]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0001]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0005]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0009]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0013]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ocular_nerve_exam.v0]/items[at0017]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0001]/value/value='Abnormal' 
        or     exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0005]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0009]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0013]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0017]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0021]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.vestibular_nerve_exam.v0]/items[at0025]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0001]/value/value='Abnormal' 
        or   	exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0005]/value/value='Abnormal' 
        or   	exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0009]/value/value='Abnormal' 
        or   	exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_other_neuro_exam.v0]/items[at0013]/value/value='Abnormal' 
        or 	    exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0001]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0005]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0009]/value/value='Abnormal' 
        or      exam/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.ichirf_extra_neuro_tests.v0]/items[at0013]/value/value='Abnormal')`
        ,
    },
    pathology: {

        title: 'Pathology',
        nextColumns: ['Overall_Interpretation'],
        select: `select
            e/ehr_id/value as ehrId,
            e/ehr_status/subject/external_ref/id/value as partyId,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Urea and electrolytes']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Urea and Electrolytes']/items[at0001]/value/value as U_and_E_Result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Urea and electrolytes']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as U_and_E_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Cholesterol']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Cholesterol']/items[at0001]/value/value as Cholesterol_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Cholesterol']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as Cholesterol_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Full blood count']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Full blood count']/items[at0001]/value/value as FBC_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Full blood count']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as FBC_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Liver function test']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Liver function test']/items[at0001]/value/value as LFT_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Liver function test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as LFT_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'C-reactive protein']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'CRP']/items[at0001]/value/value as CRP_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'C-reactive protein']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as CRP_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Thyroid function test']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'TFT']/items[at0001]/value/value as TFT_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Thyroid function test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as TFT_conclusion,    
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Endocrine test']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Endocrine']/items[at0001]/value/value as Endocrine_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Endocrine test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as Endocrine_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Hepatitis B result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Hepatitis B result']/items[at0001]/value/value as HepB_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Hepatitis B result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as HepB_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Creatine kinase result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Creatine kinase']/items[at0001]/value/value as CK_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Creatine kinase result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as CK_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolopoprotein A-I result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'APO A-I']/items[at0001]/value/value as APO_A_I_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolopoprotein A-I result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as APO_A_I_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Neurone specific enolase result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Neurone specific enolase']/items[at0001]/value/value as NSE_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Neurone specific enolase result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as NSE_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Blood lead result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Blood lead']/items[at0001]/value/value as Lead_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Blood lead result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as Lead_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'S100 result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'S100']/items[at0001]/value/value as S100_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'S100 result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as S100_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolipoprotein E genotype result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Apolipoprotein E genotype']/items[at0001]/value/value as A_E_genotype_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolipoprotein E genotype result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as A_E_genotype_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Anti-diuretic hormone result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'ADH']/items[at0001]/value/value as ADH_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Anti-diuretic hormone result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as ADH_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Osmolality result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Osmolality']/items[at0001]/value/value as Osmolality_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Osmolality result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as Osmolality_conclusion,    
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Human leukocyte antigen result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'HLA']/items[at0001]/value/value as HLA_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Human leukocyte antigen result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as HLA_conclusion,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Peth result']/data[at0001]/events[at0002]/data[at0003]/items[openEHR-EHR-CLUSTER.laboratory_test_analyte.v0, 'Peth']/items[at0001]/value/value as Peth_result,
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Peth result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value as Peth_Conclusion    
            from EHR e
            contains COMPOSITION a contains OBSERVATION obs[openEHR-EHR-OBSERVATION.laboratory_test_result.v0]`,

        where: `where
	
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Urea and electrolytes']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Cholesterol']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Full blood count']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Liver function test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'C-reactive protein']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Thyroid function test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Endocrine test']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Hepatitis B result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Creatine kinase result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolipoprotein A-I result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Neurone specific enolase result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Blood lead result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'S100 result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Apolipoprotein E genotype result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or   
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Anti-diuretic hormone result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or   
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Osmolality result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Human leukocyte antigen result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal' or
            a/content[openEHR-EHR-SECTION.adhoc.v1, 'Blood pathology']/items[openEHR-EHR-OBSERVATION.laboratory_test_result.v0, 'Peth result']/data[at0001]/events[at0002]/data[at0003]/items[at0057]/value/value='Abnormal'`
            },

    saccades: {

        title: 'Saccades',
        nextColumns: ['Overall_Interpretation'],
        select: `select
    e/ehr_id/value as ehrId,
    e/ehr_status/subject/external_ref/id/value as partyId,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0023]/items[at0004]/value/magnitude as Average_saccadic_response_time_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0023]/items[at0031]/value/value as Median_response_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0026]/items[at0005]/value/magnitude as Saccadic_response_time_SD_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0026]/items[at0037]/value/value as Saccadic_response_SD_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0024]/items[at0006]/value/magnitude as Saccade_distribution_mu_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0024]/items[at0033]/value/value as Saccadic_distribution_mu_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0025]/items[at0007]/value/magnitude as Saccade_distribution_sigma_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0025]/items[at0035]/value/value as a_a_Saccadic_distribution_sigma_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0027]/items[at0008]/value/value as Early_saccades_present,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0027]/items[at0039]/value/value as Early_saccades_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0028]/items[at0011]/value/magnitude as Early_saccades_sigma_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0028]/items[at0041]/value/value as Early_saccades_sigma_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0043]/value/value as Left_right_difference_present,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0013]/value/magnitude as Left_right_difference_magnitude,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0014]/value/value as Left_right_difference_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0030]/items[at0015]/value/value as Saccade_distribution_otherwise_normal,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0030]/items[at0047]/value/value as Distribution_otherwise_normal_Notes,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0018]/value/value as Examination_comment
from EHR e
contains COMPOSITION a
contains OBSERVATION a_a[openEHR-EHR-OBSERVATION.saccadometry.v0]`,
        where: "",
    },
    neuropsychology: {

        title: 'Neuropsychology',
        nextColumns: ['Overall_cogsport_Interpretation','Overall_Interpretation'],
        select: `select
    e/ehr_id/value as ehrId,
    e/ehr_status/subject/external_ref/id/value as partyId,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0004]/items[at0005]/value/value as Test_of_premorbid_function_estimated_FSIQ,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0004]/items[at0006]/value/value as WAIS4_vocabulary,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0008]/value/value as CVLT_II_trials_1_5_total_recall,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0010]/value/value as CVLT_II_short_delay_free_recall,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0012]/value/value as CVLT_II_long_delay_free_recall,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0013]/value/value as CVLT_II_long_delay_cued_recall,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0014]/value/value as CVLT_II_long_delay_recognition,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0015]/value/value as WAIS4_digit_span,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0016]/items[at0017]/value/value as Symbol_digit,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0016]/items[at0018]/value/value as Colour_trails_A_time,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0020]/value/value as Verbal_fluency_total,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0021]/value/value as Category_fluency_total,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0022]/value/value as Stroop_CW,
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0023]/value/value as Colour_trails_B_time,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0025]/items[at0004]/value/value as Detection_Interpretation,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0025]/items[at0027]/value/magnitude as Detection_Score_magnitude,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0028]/items[at0007]/value/value as Identification_Interpretation,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0028]/items[at0033]/value/magnitude as Identification_Score_magnitude,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0010]/value/value as One_card_learning_Interpretation,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0035]/value/magnitude as One_card_learning_Score_magnitude,    
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0030]/items[at0013]/value/value as One_back_Interpretation,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0030]/items[at0037]/value/magnitude as One_back_Score_magnitude,
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0031]/items[at0038]/value/value as Overall_cogsport_Interpretation    
    from EHR e
    contains COMPOSITION a
    contains (OBSERVATION a_a[openEHR-EHR-OBSERVATION.neuropsych_ichirf.v0] or
    OBSERVATION a_b[openEHR-EHR-OBSERVATION.cogsport_ichirf.v0]) `,
        where: ` where
	a_a/data[at0001]/events[at0002]/data[at0003]/items[at0004]/items[at0005]/value/value='Abnormal' or
	a_a/data[at0001]/events[at0002]/data[at0003]/items[at0004]/items[at0005]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0008]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0010]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0012]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0013]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0014]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0007]/items[at0015]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0016]/items[at0017]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0016]/items[at0018]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0020]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0021]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0022]/value/value='Abnormal' or
    a_a/data[at0001]/events[at0002]/data[at0003]/items[at0019]/items[at0023]/value/value='Abnormal' or
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0025]/items[at0004]/value/value='Abnormal' or
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0028]/items[at0007]/value/value='Abnormal' or
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0029]/items[at0010]/value/value='Abnormal' or    
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0030]/items[at0013]/value/value='Abnormal' or
    a_b/data[at0001]/events[at0002]/data[at0003]/items[at0031]/items[at0038]/value/value='Abnormal'`,
    }
};
export default aql;
