import React, { Component } from 'react';
import {Col, Row} from 'antd';
import CompositionList from "../../Lists/CompositionList/CompositionList";

class CompositionPage extends Component{

    render() {
        return (
            <div>
                <h2>Compositions</h2>
                <Row>
                    <Col span={24}>
                        <CompositionList cdrName={'ichirf'}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CompositionPage;
