import React from 'react';
import CDRService from "../cdr/CDRService";
const AuthContext = React.createContext();

class AuthProvider extends React.Component {

    state = { isAuth: false,
        cdrService: null
    };

    constructor() {
        super();
        this.signIn = this.signIn.bind(this);
        this.signOut = this.signOut.bind(this);
        this.state = {
            isAuth: false,
            cdrService: new CDRService('test_ichirf')
        };
    }

    signIn(username, password) {
        console.log('Username',username)


     //    const authenticated = (this.state.cdrService.cdr.username === username && this.state.cdrService.cdr.password === password);
     const authenticated = true;
        localStorage.setItem('authenticated', JSON.stringify(authenticated));
        this.setState({isAuth:  authenticated});
        return authenticated;
    }

    signOut() {
        // remove user from local storage to log user out
        localStorage.removeItem('authenticated');
        const authenticated = false;
        this.setState({isAuth:  authenticated});
        return false;
    }


    render() {
        return (
            <div>
            <AuthContext.Provider
                value={{    isAuth: this.state.isAuth,
                            cdrService: this.state.cdrService,
                            signIn : this.signIn,
                            signOut: this.signOut
                        }} >
                {this.props.children}
            </AuthContext.Provider>
            </div>

        )
    }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer }
