import React, { Component }  from "react";
import { Route , Redirect} from 'react-router-dom'
import QueryPage from "./components/Pages/QueryPage/QueryPage";
import LoginPage from "./components/Pages/LoginPage/LoginPage";
import TemplatesPage from "./components/Pages/TemplatesPage/TemplatesPage";
import CompositionPage from "./components/Pages/CompositionPage/CompositionPage";
import ParticipantPage from "./components/Pages/ParticipantPage/ParticipantPage";
import LogoutPage from "./components/Pages/LoginPage/LogoutPage";
import {AuthConsumer} from "./services/AuthContext/AuthContext";
 import DataEntryPage_old from "./components/Pages/DataEntryPage/DataEntryPage_old";
import DataEntryPage from "./components/Pages/DataEntryPage/DataEntryPage";

    class Routes extends Component {
        render() {
            return (
                <div>
                    <Route exact path="/" component={LoginPage}/>
                    <Route exact path="/login" component={LoginPage}/>
                    <SecretRoute exact path="/templates" component={TemplatesPage}/>
                    <SecretRoute exact path="/dataentry" component={DataEntryPage}/>
                    <SecretRoute exact path="/participants" component={ParticipantPage}/>
                    <SecretRoute exact path="/compositions" component={CompositionPage}/>
                    <SecretRoute exact path="/queries" component={QueryPage}/>
                    <SecretRoute exact path="/logout" component={LogoutPage}/>
                </div>
            )
        }
    }

const SecretRoute = ({ component: Component, ...rest }) => (

    <AuthConsumer>
        { ({ isAuth }) => (


        <Route {...rest} render={(props) => (
            isAuth ? <Component {...props} /> : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
        )} />

        )}
    </AuthConsumer>

);
    export default Routes;
