import React, {Component} from 'react';
import {AuthConsumer} from "../../../services/AuthContext/AuthContext";
import {Col, Row, Button, Form} from "antd";



let contextSignOut = null;
const FormItem = Form.Item;

class LogoutPage extends Component {


    handleSubmit = (e) => {
        e.preventDefault();
        contextSignOut();
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        this.props.history.push(from);
    };

    render() {

        return (
            <div>
                <AuthConsumer>
                {
                    ({ signOut}) => {
                        contextSignOut = signOut
                    }
                }
                </AuthConsumer>

            <Row type="flex" justify="center">

                <Col span={8} offset={2}>
                    <Form onSubmit={this.handleSubmit} className="login-form">

                        <FormItem>

                            <Button type="primary" htmlType="submit">
                                Log out of ICHIRF Registry Analytics
                            </Button>
                        </FormItem>
                    </Form>
                </Col>
            </Row>

            </div>


        )
    }
}

export default LogoutPage;
