import React, {Component, useEffect, useState} from 'react';
import {Layout, Row,Col,Button, Checkbox, Alert } from 'antd';
import { css } from 'react-emotion';
import {BeatLoader} from "react-spinners";
import ResultsTable from "../../Lists/ResultsTable";
import CDRService from '../../../services/cdr/CDRService';
import DemographicService from '../../../services/cdr/DemographicService';

import aql from '../../../services/aql/aql';

const { Content, Header } = Layout

const QueryPage  = () => {


    const [loading, setLoading] = useState(false);
    const [participantList, setParticipantList] = useState(null);
    const [data, setData] = useState(null);
    const [queryId, setQueryId] = useState('neurology');
    const [resultText, setResultText] = useState(null);
    const [excludeControls, setExcludeControls] = useState(false);
    const [abnormalsOnly, setAbnormalsOnly] = useState(true);
    const [cdrService, setCdrService] = useState(new CDRService('ichirf'));
    const [error, setError] = useState({});


    const getParticipantList = async() => {
        const demogService = new DemographicService(cdrService);
        const pList = await demogService.queryPatients({lastNames: '**'});
        setParticipantList(pList);
    }

    useEffect(() => {
      getParticipantList()
        console.log('GetParticipantEffect')
    }, []);


    useEffect( () => {
        getUpdatedSet();
        console.log('GetResultSetEffect')
    }, [abnormalsOnly,queryId,participantList,excludeControls]);

    const yearOfBirth = (dateofBirth) => {
        return dateofBirth.substr(0,4);
    }

    const ichirfStatus = (ichirfId) => ichirfId.substr(3,1)

   const genderCode = (gender) => gender.substr(0,1);

    const getUpdatedSet = async() => {

        let data = null;
        if (!queryId) return;

        try {

            setLoading(true)
            setError(false)
            setData(null)

            const active = aql[queryId];
            let aqlString = active.select;

            if (abnormalsOnly){
                aqlString = aqlString.concat(active.where);
            }

           // console.log('aql: ',aqlString)

            const resultSet = await cdrService.runQuery(aqlString);

         //   console.log('rs: ',resultSet)
            let filteredData;

            if (participantList && resultSet) {

            filteredData = resultSet.map((resultRow) => {
                    const participantFound = participantList.find(participant => participant.id === resultRow.partyId);
                        return {
                            Status: ichirfStatus(participantFound.lastNames),
                            ichirfId: participantFound.lastNames,
                            YoB: yearOfBirth(participantFound.dateOfBirth),
                            MF: genderCode(participantFound.gender),
                            ...resultRow
                        };
                })
                .filter( (resultRow) => {
                   return (!excludeControls || resultRow.Status !== 'C')
                })
            }
//console.log('rs: ',resultSet)
            setLoading(false)
            setData( filteredData)
            setResultText(active.title)

        } catch (e) {
            //alert(`Query failed : ${e.message}`);
            setError({error: true, errorMessage: e.message});
    //        this.setState({loading:false});
        }
    }

    const getResultText = ()  => {
        if (queryId && data) {
            const title = aql[queryId].title;
            let abnormalsText = abnormalsOnly ? "Abnormal" : "All";
            let controlsText = excludeControls ? '[Controls excluded]' : "";
            return `${abnormalsText} ${title} Results (${data.length}) ${controlsText}`;
        }
        else return ""
    };

    const getKeyColumns = ()  => {
        if (queryId && data)
            return aql[queryId].nextColumns
    };

    const handleClick = async queryId => {
        setQueryId(queryId)
    };


    const onAbnormalsCheckChange = async e => {
        setAbnormalsOnly(e.target.checked)
    };

    const onControlsCheckChange = async e => {
        setExcludeControls(e.target.checked)
    };

    const loader = () => {

        if (!error) {

            if (loading)  {

                const override =
                    css`
                    display: block;
                    margin: 0 auto;
                    border-color: red;`;
                return (
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={40}
                            color={'#49bc67'}
                            loading={loading}
                        />
                    </div>)
            } else {
                //                  return (<h2>{`${this.getResultText()} : ${this.state.data.length}`}</h2>)
            }
        }
    };
const alert = () => {
        if (error) {
            return (<Alert
                message="Query failed"
                description={error.errorMessage}
                type="error"
                closable
            />)
        }
    };

   const reportControls = () => {

        return(
            <>
                <Col span={1}/>
                <Col span={14}>
                    <Button onClick={() => handleClick('neurology')}>
                        Neurology
                    </Button>
                    <Button onClick={() => handleClick( 'exam')}>
                        Neuro exam
                    </Button>
                    <Button onClick={() => handleClick( 'imaging')}>
                        Imaging
                    </Button>
                    <Button onClick= {() => handleClick( 'neuropsychology')}>
                        Neuropsychology
                    </Button>
                    <Button onClick={() => handleClick( 'pathology')}>
                        Pathology
                    </Button>
                    <Button onClick={() => handleClick( 'saccades')}>
                        Saccades
                    </Button>
                </Col>
                <Col span={1}/>
                <Col span={4}>
                    <Checkbox onClick={onAbnormalsCheckChange} checked={abnormalsOnly}>
                        Only display Abnormals
                    </Checkbox>
                </Col>
                <Col span={4}>
                    <Checkbox onClick={onControlsCheckChange} checked={excludeControls}>
                        Exclude Controls
                    </Checkbox>
                </Col>
            </>
        )
    }

        return (
            <>
                    <Row style={{alignItems: 'center'}}>
                        <Col span={24}>{reportControls()}</Col>
                    </Row>

                    <Row>
                        <Col span={24} offset={10}>{loader()}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>{alert()}</Col>
                    </Row>
                <Row>
                    <Col span={24}>
                        <ResultsTable
                            title={getResultText()}
                            resultSet={data}
                            keyColumns={getKeyColumns()}
                        />
                    </Col>
                </Row>

            </>
        )
}

export default QueryPage;
