const CDRConfigs =[
    {
        name: 'c4h_nutshell',
        type: 'thinkehr',
        url: 'https://cdr.code4health.org',
        username: 'ianmcnicoll_98aa716e-8bc6-40f6-a5a3-84518c4c60ef',
        password: '$2a$10$7RUqg',
    },
    {
        name: 'ethercis_cloud',
        type: 'ethercis',
        url: 'http://178.62.71.220:8080',
        username: 'guest',
        password: 'guest',
    },
    {
        name: 'ichirf',
        type: 'thinkehr',
        url: 'https://cdr.ichirf.freshehr.com',
        username: 'ichirf',
        password: 'sA3w4Hwm',
    },
    {
        name: 'ichirf_admin',
        type: 'thinkehr',
        url: 'https://cdr.ichirf.freshehr.com',
        username: 'ichirf_admin',
        password: 'Sk953CmH',
    },
    {
        name: 'ichirf_demog',
        type: 'thinkehr',
        url: 'https://demog.ichirf.freshehr.com',
        username: 'ichirf',
        password: 'sA3w4Hwm',
    },
    {
        name: 'ichirf_test',
        type: 'thinkehr',
        url: 'https://cdr.code4health.org',
        username: 'b29ecade-eb65-4748-8a61-0156421c26ad',
        password: '$2a$10$619ki',
    }


];

export default CDRConfigs;

