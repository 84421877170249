/* eslint-disable prefer-destructuring */
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import ReactTable from 'react-table';
import DemographicService from "../../../services/cdr/DemographicService";
import CDRService from "../../../services/cdr/CDRService";
import {Button, Radio, Col, Row, Layout} from "antd";
import {CSVLink} from "react-csv";
import {css} from "react-emotion";
import {BeatLoader} from "react-spinners";
import BetterRender from "./BetterRender";
import TestRender from "./TestRender";

const { Content, Header } = Layout

const DataEntryPage_old = (props) => {

    const downloadCSV = (event) => {
        const columns = reactTable.current.state.columns;
        if (!columns) {
            return
        }
        const currentRecords = reactTable.current.getResolvedState().sortedData;
        var data_to_download = [];
        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {};
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
            }
            data_to_download.push(record_to_download)

        }
        setCsvData(data_to_download)

    }

    const formattedDate = () => {
        const today = new Date();
        const yr = today.getFullYear();
        const mth = today.getMonth() + 1;
        const dy = today.getDay();
   //     return `${yr}-${mth}-${dy}`;
        return today.toLocaleDateString()
    }

    const ichirfStatus = (ichirfId) => {
        return ichirfId.substr(3, 1)
    };

    const handleTemplateClick = async formId => {
        setFormId(formId)
    };

    const findEhrId = async () => {
        if (!subjectId) return
        const ehrId = await cdrService.findEhrIdBySubjectId(subjectId, 'uk.nhs.nhs_number');
        setEhrId(ehrId)
    };

    const handleParticipantSelect = async (rowInfo) => {
        setIchirfId(rowInfo.row.lastNames)
        setSelected(rowInfo.index)
        setSubjectId(rowInfo.row.id)

    };

    const findComposition = async () => {

        if (!formId || !ehrId) return

        setLoading(true)
        const aqlString = `SELECT c/archetype_details/template_id/value as templateId,
           c/uid/value as uid,
           e/ehr_id/value as ehrId
            FROM EHR e
            CONTAINS COMPOSITION c
            WHERE c/archetype_details/template_id/value = '${formId}'
            AND e/ehr_id/value = '${ehrId}'
            ORDER BY c/context/start_time/value DESC
            OFFSET 0 LIMIT 1`

        const resultSet = await cdrService.runQuery(aqlString);

        if (resultSet) {
  //          console.log('Composition ID: ', resultSet[0].uid)
            setCompositionId(resultSet[0].uid)
        }
        else
            setCompositionId('')
        setLoading(false)
    }

    const getParticipantList = async () => {
        try {
            setLoading(true)
            const demogService = new DemographicService(cdrService);
            const pList = await demogService.queryPatients({lastNames: '**'});
            const participList = pList.map((resultRow) => {
                return {
                    status: ichirfStatus(resultRow.lastNames),
                    ...resultRow
                };
            });
            setParticipantList(participList);
            setLoading(false)

        } catch (e) {
            alert(e.message)
        }
    }

    const formButtons = () => {
        if (ehrId && formId) {
            return (
                <>
                    <Radio.Group value = {formId}>
                    <Radio.Button value="ICHIRF_Registry_Report_Part1_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part1_v0')}>
                        Report Part 1
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part2_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part2_v0')}>
                        Report Part 2
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part3_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part3_v0')}>
                        Report Part 3
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part4_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part4_v0')}>
                        Report Part 4
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Neurology_Report_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Neurology_Report_v0')}>
                        Neurology
                    </Radio.Button>
                    </Radio.Group>
                </>)
        }
    };

    const loader = () => {

        if (!error) {
            if (loading ) {
                const override =
                    css`
                        display: block;
                        margin: 0 auto;
                        border-color: red;`;
                return (
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={40}
                            color={'#49bc67'}
                            loading={loading}
                        />
                    </div>)
            } else return null
        }
    };


    const displayBetterForm = () => {

        if (!ehrId ||!formId)
          return null

        else
            return (
                    <BetterRender
                        style={{ padding: '0 50px',
                            overflowY: 'scroll',
                            height: '500px' }}
                        ehrId={ehrId}
                        compId={compositionId}
                        formName={formId}
                        userName={'ichirf'}
                        password={'sA3w4Hwm'}
                    />
            )
    }

    const [loading, setLoading] = useState(false);
    const [participantList, setParticipantList] = useState([]);
    const [formId, setFormId] = useState('ICHIRF_Registry_Report_Part1_v0');
    const [subjectId, setSubjectId] = useState('');
    const [selected, setSelected] = useState(false);
    const [compositionId, setCompositionId] = useState(null);
    const [ehrId, setEhrId] = useState('');
    const [ichirfId, setIchirfId] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [cdrService, setCdrService] = useState(new CDRService('ichirf'));
    const [error, setError] = useState('');

  //  const [data, setData] = useState([]);

    useEffect(() => {
        console.log('participantEffect')
        getParticipantList()
    }, []);

    useEffect(() => {
        findComposition()
        console.log(`new Form compositionEffect = ${formId}`)
    }, [formId]);

    useEffect(() => {
        findComposition()
        console.log(`new ehr compositionEffect = ${ehrId}`)
    }, [ehrId]);

    useEffect(() => {
        findEhrId()
        console.log(`Hew subject ehr Effect = ${subjectId}`)
    }, [subjectId]);

    const reactTable = useRef(null)
    const tableStyle = {fontSize: '14px'}

   // console.log("ehrId: ", ehrId)
   // console.log("compositionId: ", compositionId)
   // console.log("formId: ", formId)
   // console.log("subjectId: ", subjectId)

   if (!participantList)
        return (
            <Layout>
                < Header className="header"
                         style={{
                             position: 'fixed',
                             width: '100%',

                         }}>
            <div>
                <Row>
                    <Col span={24} offset={10}>
                        {loader()}
                    </Col>
                </Row>
            </div>
                    </Header>
            </Layout>
            )
   else return(
        <div>
            <Row gutter={2} style={{marginTop: 4}}>
                <Col span={9}>
                    <Layout>
                        < Header className="header"
                                 style={{
                                     position: 'sticky',
                                     top: 0,
                                     zIndex: 1,
                                     width: '100%',
                                     background: '#fff'
                                 }}>
                            <Row>
                                <Col span={10}>
                                    <CSVLink data={csvData} filename={`${formattedDate()}.csv`}
                                             target="_blank" onClick={ () => downloadCSV() } >
                                        Download CSV
                                    </CSVLink>
                                </Col>
                                <Col span={14}>
                                    {<h4>{ichirfId?ichirfId:`Select a Participant`}</h4>}
                                </Col>
                            </Row>
                        </Header>
                     <Content>
                         <Row>
                             <Col span={24}>
                                 <ReactTable
                                     ref={reactTable}
                                     filterable
                                     className="-striped -highlight"
                                     data={participantList}
                                     style={tableStyle}
                                     columns={[
                                         {
                                             Header: 'ICHIRF Id',
                                             accessor: 'lastNames', // String-based value accessors!
                                             width: 120,
                                             filterMethod: (filter, row) => row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase())
                                         },
                                         {
                                             Header: 'Status',
                                             accessor: 'status',
                                             width: 70,
                                             filterMethod: (filter, row) => row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase())

                                         },
                                         {
                                             Header: 'Gender',
                                             accessor: 'gender',
                                             filterMethod: (filter, row) => row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase()),
                                             width: 80,

                                         },
                                         {
                                             Header: 'Date of Birth',
                                             accessor: 'dateOfBirth',
                                             width: 120,
                                             filterMethod: (filter, row) => row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase())

                                         },
                                         {
                                             Header: 'PartyId',
                                             accessor: 'id', // String-based value accessors!
                                             width: 70,
                                             filterMethod: (filter, row) => row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase())
                                         },
                                     ]}
                                     defaultPageSize={100}
                                     defaultSorted={[{
                                         id: 'lastNames',
                                         desc: false,
                                     }]}
                                     getTrProps={(state, rowInfo, column) => {
                                         if (rowInfo && rowInfo.row) {
                                             return {
                                                 onClick: async (e) => {
                                                     await handleParticipantSelect(rowInfo);
                                                 },
                                                 style: {
                                                     background: rowInfo.index === selected ? '#00afec' : 'white',
                                                     color: rowInfo.index === selected ? 'white' : 'black'
                                                 }
                                             }
                                         } else {

                                         }
                                         return {}
                                     }
                                     }
                                 />
                             </Col>
                         </Row>
                     </Content>
                    </Layout>


                </Col>
                <Col span={15} >
                    <Layout className={'layout'} >

                        < Header className="header"
                                 style={{
                                     position: 'sticky',
                                     top: 0,
                                     zIndex: 1,
                                     width: '100%',
                                     display: 'flex',
                                     background: '#fff'
                                 }}>
                        <Row>
                            <Col>
                                {formButtons()}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {loader()}
                            </Col>
                        </Row>
                    </Header>

                   <Content>
                        <Row gutter={4}>
                            <Col>
                                {displayBetterForm()}
                           </Col>
                        </Row>
                   </Content>
                </Layout>
                </Col>
            </Row>
        </div>
   );
}

export default DataEntryPage_old;


