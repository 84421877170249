import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import CDRService from "../../../services/cdr/CDRService";
import {Radio, Col, Row, Layout} from "antd";
//import {CSVLink} from "react-csv";
import {css} from "react-emotion";
import {BeatLoader} from "react-spinners";
import BetterRender from "./BetterRender";
//import TestRender from "./TestRender";
import ParticipantTable from "./ParticipantTable";
import CDRName from "../../Context/CDRName";

const { Content, Header } = Layout

const DataEntryPage = () => {

    const handleTemplateClick = async formID => {
        setFormName(formID)
    };

    const findEhrId = async (subjectId) => {
        if (!subjectId) return
        const ehrId = await cdrService.findEhrIdBySubjectId(subjectId, 'uk.nhs.nhs_number');
        setEhrId(ehrId)
    };

    const findComposition = async () => {

        if (!formName || !ehrId) {
            setCompositionId(null)
            return
        }

        setLoading(true)
        const aqlString = `SELECT c/archetype_details/template_id/value as templateId,
           c/uid/value as uid,
           e/ehr_id/value as ehrId
            FROM EHR e
            CONTAINS COMPOSITION c
            WHERE c/archetype_details/template_id/value = '${formName}'
            AND e/ehr_id/value = '${ehrId}'
            ORDER BY c/context/start_time/value DESC
            OFFSET 0 LIMIT 1`

        const resultSet = await cdrService.runQuery(aqlString);

        if (resultSet) {
  //          console.log('Composition ID: ', resultSet[0].uid)
            setCompositionId(resultSet[0].uid)
        }
        else
            setCompositionId(null)
        setLoading(false)
    }

    const formButtons = () => {
        if (ehrId) {
            return (
                <>
                    <Radio.Group value = {formName}>
                    <Radio.Button value="ICHIRF_Registry_Report_Part1_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part1_v0')}>
                        Report Part 1
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part2_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part2_v0')}>
                        Report Part 2
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part3_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part3_v0')}>
                        Report Part 3
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Report_Part4_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Report_Part4_v0')}>
                        Report Part 4
                    </Radio.Button>
                    <Radio.Button value="ICHIRF_Registry_Neurology_Report_v0" onClick={() => handleTemplateClick('ICHIRF_Registry_Neurology_Report_v0')}>
                        Neurology
                    </Radio.Button>
                    </Radio.Group>
                </>)
        }
    };

    const loader = () => {
        if (!error) {
            if (loading ) {
                const override =
                    css`
                        display: block;
                        margin:  auto;
                        border-color: red;
                        padding: 0 50px`;
                return (
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={30}
                            color={'#4994bc'}
                            loading={loading}
                        />
                    </div>)
            } else return null
        }
    };


    const displayBetterForm = () => {

        if (ehrId && formName && !loading)
            return (
                <BetterRender
                    style={{
                        padding: '0 50px',
                        overflowY: 'scroll',
                        height: '500px'
                    }}
                    ehrId={ehrId}
                    compId={compositionId}
                    formID={formName}
                    userName={cdrService.cdr.username}
                    password={cdrService.cdr.password}
                />
            )

    }

    const onParticipantSelect = (rowInfo) => {
       if (rowInfo?.row)
           findEhrId(rowInfo.row.id)
    };

    const [loading, setLoading] = useState(false);
    const [participantList, setParticipantList] = useState([]);
    const [formName, setFormName] = useState('ICHIRF_Registry_Report_Part1_v0');
    const [compositionId, setCompositionId] = useState(null);
    const [ehrId, setEhrId] = useState('');
    const [cdrService, setCdrService] = useState(new CDRService(useContext(CDRName)));
    const [error, setError] = useState('');

    useEffect(() => {
        findComposition()
        console.log(`new Form compositionEffect = ${formName}`)
    }, [formName,ehrId]);

   if (participantList)

    return (
        <Layout className="layout">
            < Header className="header"
                     style={ {
                         position: 'fixed',
                         width: '100%',
                         background: '#fff'
                    } }>
                <Row>
                      <Col span={24} offset={10}> {loader()}</Col>
                </Row>
            </Header>
            <Content>
                <Row gutter={4} style={ {marginTop: 4 } }>
                    <Col span={10}>
                       <ParticipantTable parentParticipantSelect={onParticipantSelect} />
                    </Col>
                    <Col span={14} >
                        <Row>
                            <Col>{formButtons()}</Col>
                        </Row>
                        <Row>
                            <Col>{loader()}</Col>
                        </Row>
                        <Row>
                            <Col>{displayBetterForm()}</Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
        )
}

export default DataEntryPage;


