import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Menu} from 'antd';
import {withRouter} from "react-router";
import './Navigation.css'

const SubMenu = Menu.SubMenu;

const Navigation = ({ location }) => {
    //console.log('location: ', location)
    return (
        <div>
            <Menu className={'brand'}
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={['/queries']}
                selectedKeys={[location.pathname]}
            >
                <Menu.Item key="/queries">
                    <Link to="/queries">
                        Run analyses
                    </Link>
                </Menu.Item>
                <Menu.Item key="/dataentry">
                    <Link to="/dataentry">
                        Data entry
                    </Link>
                </Menu.Item>

                <Menu.Item style={{float: 'right'}} key="/logout">
                    <Link to="/logout">
                        Logout
                    </Link>
                </Menu.Item>


                <SubMenu style={{float: 'right'}} title='Tools'>
                    <Menu.Item key="/templates">
                        <Link to="/templates">
                            Templates
                        </Link></Menu.Item>

                    <Menu.Item key="participants">
                        <Link to="/participants">
                            Participants
                        </Link>
                    </Menu.Item>


                    <Menu.Item key="compositions">
                        <Link to="/compositions">
                            Compositions
                        </Link>
                    </Menu.Item>


                </SubMenu>
            </Menu>

        </div>
    );
}

export default withRouter(Navigation);
