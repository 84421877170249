

import React, { Component } from 'react';
import {Form, Input, DatePicker, Button, Row, Col, Card,  message} from 'antd';
import './AddParticipant.css';
import CDRService from "../../../services/cdr/CDRService";
import DemographicService from "../../../services/cdr/DemographicService";


const FormItem = Form.Item;


class AddParticipant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cdrService: props.cdrService,
            parentCallback: props.parentCallback,
            partyId: undefined,
            ehrId: undefined,
            genderLabel: undefined,
            shortParticipantId: undefined,
            participantId: undefined,
            doB: undefined
        }
    }

    composeFirstName(participantId) {
        const gChars = participantId.slice(0, 1);
        const idChars = participantId.slice(4, 8);
        return gChars+idChars;

    }

    composeGender(participantId) {
        const genderCode = participantId.slice(0, 1)
        return (genderCode === 'A') ? 'MALE' : 'FEMALE'
    }

    commitParticipant = async (participantId,dateOfBirth) => {
        const partyId = await this.createParty(participantId,dateOfBirth)
        const ehrId =  await this.createEhr(partyId,participantId)

        console.log(partyId);
        console.log(ehrId);
        if (ehrId !== undefined && partyId !== undefined){
            this.setState({ehrId, partyId});
            message.success(`New participant: ${participantId} successfully registered`,5);
            this.state.parentCallback(partyId);
            this.handleReset();
            this.setState(   {partyId: undefined,
                ehrId: undefined,
                genderLabel: undefined,
                shortParticipantId: undefined,
                participantId: undefined,
                doB: undefined})
        }
    }

    createParty = async (participantId,dateOfBirth) => {

        let partyId = undefined
        const party = {
            firstNames: this.composeFirstName(participantId),
            lastNames: participantId,
            gender: this.composeGender(participantId),
            dateOfBirth: dateOfBirth,
        };


        try {
            const demogService = new DemographicService(this.state.cdrService);
            const response = await demogService.commitParty(party);
            console.log('response ', response)
            return response.data.id;

  //          return hRef.split(this.state.cdrService.restUrl('demographics/inidus/party/'))[1];

        }
        catch (e) {
            message.error(`${participantId} could not be registered ... ${e.message}`,5);
            console.log(e.message)
        }


    }

    createEhr = async (partyId, participantId) => {

        try {
            return await this.state.cdrService.createEhr(partyId,'uk.nhs.nhs_number');
        }
        catch (e) {
            message.error(`${participantId} could not be registered ... ${e.message}`,5);
            console.log(e.message)
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
             const partyId  = this.commitParticipant(this.props.form.getFieldValue('Participant ID'),this.props.form.getFieldValue('Date of Birth').format('YYYY-MM-DD'))
            }
        });
    };

    handleParticipantIdChange = (e) => {
        e.preventDefault();
        const participantId = e.target.value;
        const genderLabel  = this.composeGender(participantId)
        const shortParticipantId = this.composeFirstName(participantId)
        this.setState({genderLabel, shortParticipantId,participantId} )
    };

    handleDoBChange = (date, dateString) => {

        this.setState({doB: dateString})
    };

    handleReset = () => {
        this.props.form.resetFields();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 10},
                sm: { span: 10 },
            },
            wrapperCol: {
                xs: { span: 6},
                sm: { span: 6},
            },
        };
        return (
            <div>
                <Row gutter={16} style={ {marginTop: 8} }>
                    <Col span={6}>
                    </Col>
                    <Col span={18}>
                        <h3>Participant details</h3>
                    </Col>
                </Row>

                <Row>
                    <Col span={1}/>
                    <Col span={23} >
                        <Form {...formItemLayout} onSubmit={this.handleSubmit} className="participant-form" >
                            <FormItem label="Participant ID" >
                                {getFieldDecorator('Participant ID', {
                                    rules: [{ required: true, message: 'Please input the participant Id!' }],
                                })(
                                    <Input  placeholder="Participant Id" style={{ width: 200 }}
                                            onChange = { this.handleParticipantIdChange }
                                    />
                                )}
                            </FormItem>

                            <FormItem label="Date of Birth">
                                {getFieldDecorator('Date of Birth', {
                                    rules: [{ required: true, message: 'Please input the Date of Birth' }],
                                })(
                                    <DatePicker style={{ width: 200 }} format='DD.MM.YYYY' placeholder= "DD.MM.YY format"
                                    onChange={ this.handleDoBChange }/>
                                )}
                            </FormItem>
                            <FormItem>
                                <Button type="primary" htmlType="submit" className="save-form-button" style={{ width: 400 }}>
                                    Add Participant
                                </Button>
                            </FormItem>

                            <Card style={{ width: 430 }}>
                                <p>Participant ID:  {this.state.participantId}</p>
                                <p>DoB:  {this.state.doB}</p>
                                <hr/>
                                <p>Short ID:  {this.state.shortParticipantId}</p>
                                <p>Gender:  {this.state.genderLabel}</p>


                            </Card>,


                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default Form.create()(AddParticipant);

