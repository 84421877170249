import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { css } from 'react-emotion';
import CDRService from "../../../services/cdr/CDRService";
import {BeatLoader} from "react-spinners";

class TemplateList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            templateList: [],
            cdrService: new CDRService(props.cdrName),
            loading: false
        };
    }

    async componentDidMount() {
        this.setState( {loading: true});
        try {
        //    await this.state.cdrService.setActiveCDR(');
            const templateList = await this.state.cdrService.listRegisteredTemplates();
            this.setState({templateList});
            this.setState( {loading: false});
        } catch (e) {
            alert(e.message)
        }

    }

    render() {
        if (!this.state.templateList){
          return  <div>No templates</div>
        }
        else {
            const override =
                css`
                display: block;
                margin: 0 auto;
                border-color: red;`;

            const title = `CDR name: ${this.state.cdrService.cdr.name}`;

            return (

                <div>
                    <h4>{title}</h4>
                    <Table striped bordered condensed hover>
                        <thead>
                        <tr>
                            <th>Template Id</th>
                            <th>Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.templateList.map(template => (
                            <tr key={`${template.templateId}-${template.createdOn}`}>
                                <td>{template.templateId}</td>
                                <td>{template.createdOn}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={25}
                            color={'#49bc67'}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );
        }
    }
}

export default TemplateList;
