import React from 'react';
import {Layout} from 'antd';
import './AppHeader.css';
import cogLeaf from './cog-leaf-3.png';
import Routes from "../../routes";
import {AuthConsumer} from '../../services/AuthContext/AuthContext';
import Navigation from "../Navigation/Navigation";
import logoUrl from "./ichirf_header.jpg";

const {Content, Header} = Layout;


class AppHeader extends React.Component {


    render() {
        return (
            <div>
                <Layout className="layout">
                    < Header className="header"
                    style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        width: '100%',
                        display: 'flex',
                        background: '#fff'
                    }}>
                    <div className="logo">
                            <img
                                src={logoUrl}
                                alt='ICHIRF'
                                width='100%'
                                height='100%'
                            />
                             <span>
                                 <img
                                     src={cogLeaf}
                                     alt='inidus'
                                     width='2%'
                                     height='2%' />

                             </span>
                        </div>
                        <div className='banner'>ICHIRF Registry</div>

                        <AuthConsumer>
                            {({ isAuth }) => (
                                <div>
                                    { isAuth ? ( <Navigation/> ) : null }
                                </div>
                            )}
                        </AuthConsumer>

                    </Header>
                    <Content style={{padding: '0 10px', background: '#fff'}}>

                        <Routes/>

                    </Content>
                </Layout>
            </div>
        );
    }
}
    export default AppHeader;
