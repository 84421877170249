import React, {Component} from 'react';
import AppHeader from './components/Header/AppHeader';
import CDRService from "./services/cdr/CDRService";
import 'antd/dist/antd.css';
import CDRName from "./components/Context/CDRName";

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            authenticated: false,
            cdrService: new CDRService('ichirf')
       }
    }

    componentDidMount() {
    }

    signIn(username, password) {
      ;
        const authenticated = (this.cdrService.cdr.username === username && this.cdrService.cdr.password === password);
        this.setState({authenticated});
        return authenticated;
    }

    render() {
        return (
             <CDRName.Provider value={'ichirf'}>
                <AppHeader/>
            </CDRName.Provider>
        );
    }
}

export default App;
