import React, { Component } from 'react';
import {Col, Row} from 'antd';
import ParticipantList from "../../Lists/ParticipantListOld/ParticipantList";
import AddParticipant from "./AddParticipant";
import CDRService from "../../../services/cdr/CDRService";


class ParticipantPage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cdrService:  new CDRService('ichirf'),
            partyId: undefined
        };
 //       console.log('Particpage-cdrProps',props.cdrName)
    }

    callbackAddParticipant = (partyId) => {
        this.setState({partyId})
    };

    render() {

        return (
            <div>
               <h2> {`Participants ${this.state.partyId}` }</h2>
                <Row className="show-grid">
                    <Col span={15}>
                        <ParticipantList cdrService= {this.state.cdrService} partyId={ this.state.partyId}/>
                    </Col>
                    <Col gutter= {3} span={9}>
                        <AddParticipant cdrService= {this.state.cdrService} parentCallback = { this.callbackAddParticipant }/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ParticipantPage;
