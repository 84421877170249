import DemographicService from "../../../services/cdr/DemographicService";
import React, {useEffect, useRef, useState, useContext} from "react";
import CDRService from "../../../services/cdr/CDRService";
import {Col, Layout, Row} from "antd";
import {CSVLink} from "react-csv";
import ReactTable from "react-table";
import CDRName from "../../Context/CDRName";
import {css} from "react-emotion";
import {BeatLoader} from "react-spinners";

const { Content, Header } = Layout

const ParticipantTable = ({parentParticipantSelect}) => {

    const [cdrService, setCdrService] = useState(new CDRService(useContext(CDRName)));
    const [loading, setLoading] = useState(false);
    const [participantList, setParticipantList] = useState([]);
    const [ichirfId, setIchirfId] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        setLoading(true)
        getParticipantList()
        setLoading(false)
    }, [cdrService]);

    const formattedDate = () => {
        const today = new Date();
        return today.toLocaleDateString()
    }

    const ichirfStatus = (ichirfId) => {
        return ichirfId.substr(3, 1)
    };

    const handleParticipantSelect = async (rowInfo) => {
        setSelected(rowInfo.index)
        setIchirfId(rowInfo.row.lastNames)
        parentParticipantSelect(rowInfo)
    };

    const downloadCSV = (event) => {

        const columns = reactTable.current.state.columns;
        if (!columns) {
            return
        }
  /*      const data_to_download = [];
        const currentRecords = reactTable.current.getResolvedState().sortedData;

        for (var index = 0; index < currentRecords.length; index++) {
            let record_to_download = {};

            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
            }
            data_to_download.push(record_to_download)
*/
            const data_to_download = reactTable.current.getResolvedState().sortedData.map(record => {
                let record_to_download = {};
                columns.map(column => {
                    record_to_download[column.Header] = record[column.accessor]
                });

                return record_to_download;
            })
        setCsvData(data_to_download)
    }

const getParticipantList = async () => {
        try {
            const demogService = new DemographicService(cdrService);
            const pList = await demogService.queryPatients({lastNames: '**'});
            const participList = pList.map((resultRow) => {
                return {
                    status: ichirfStatus(resultRow.lastNames),
                    ...resultRow
                };
            });
            setParticipantList(participList);

        } catch (e) {
            alert(e.message)
        }
    }

const columnFilterMethod  = (filter,row) => {
   return row[filter.id].toUpperCase().startsWith(filter.value.toUpperCase())
}

const loader = () => {

            if (loading ) {
                const override =
                    css`
                        display: block;
                        margin: 0 auto;
                        border-color: red;`;
                return (
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={40}
                            color={'#49bc67'}
                            loading={loading}
                        />
                    </div>)
            } else return null
    };

const reactTable = useRef(null)
const tableStyle = {fontSize: '14px', padding: '0 0px', overflowY: 'scroll', height: '1000px'}

    if (!participantList) return

    return (
        <Layout className="layout">
                <Row>
                    <Col span={10}>
                        <CSVLink data={csvData}
                                 filename={`Participants-${formattedDate()}.csv`}
                                 target="_blank"
                                 onClick={() => downloadCSV()}>
                            Download CSV
                        </CSVLink>
                    </Col>
                    <Col span={14}>
                        {<h4>{ichirfId ? ichirfId : `Select a Participant`}</h4>}
                    </Col>
                </Row>
                <Row>
                   <Col span={24}>
                       {loader()}
                       <ReactTable
                           ref={reactTable}
                           filterable
                           className="-striped -highlight"
                           data={participantList}
                           style={tableStyle}
                           columns={[
                               {
                                   Header: 'ICHIRF Id',
                                   accessor: 'lastNames', // String-based value accessors!
                                   width: 120,
                                   filterMethod: columnFilterMethod
                               },
                               {
                                   Header: 'Status',
                                   accessor: 'status',
                                   width: 70,
                                   filterMethod: columnFilterMethod

                               },
                               {
                                   Header: 'Gender',
                                   accessor: 'gender',
                                   width: 80,
                                   filterMethod: columnFilterMethod,


                               },
                               {
                                   Header: 'Date of Birth',
                                   accessor: 'dateOfBirth',
                                   width: 120,
                                   filterMethod: columnFilterMethod,
                               },
                               {
                                   Header: 'PartyId',
                                   accessor: 'id', // String-based value accessors!
                                   width: 70,
                                   filterMethod: columnFilterMethod,
                                                                  },
                           ]}
                           defaultPageSize={100}
                           defaultSorted={[{
                               id: 'lastNames',
                               desc: false,
                           }]}
                           getTrProps={(state, rowInfo, column) => {
                               if (rowInfo && rowInfo.row) {
                                   return {
                                       onClick: (e) => handleParticipantSelect(rowInfo),
                                       style: {
                                           background: rowInfo.index === selected ? '#00afec' : 'white',
                                           color: rowInfo.index === selected ? 'white' : 'black'
                                       }
                                   }
                               } else {

                               }
                               return {}
                           }
                           }
                       />

                   </Col>
                </Row>
            </Layout>
    )

}

export default ParticipantTable
