/* eslint-disable prefer-destructuring */
import React from 'react';
import ReactTable from 'react-table';
import DemographicService from "../../../services/cdr/DemographicService";
import CDRService from "../../../services/cdr/CDRService";
import {Button, Col, Row} from "antd";
import {CSVLink} from "react-csv";


class ParticipantList extends React.Component {
    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
        console.log('CDR service', props.cdrService)
        this.state = {
            loading: false,
            templateList: [],
            cdrService: props.cdrService,
            demogService: new DemographicService(props.cdrService),
            dataToDownload: [],
            partyId: props.partyId,
            selected: undefined
        };
    }

        download(event) {
            const columns = this.reactTable.state.columns;
            if (!columns){ return  };
            const currentRecords = this.reactTable.getResolvedState().sortedData;
            var data_to_download = [];
            for (var index = 0; index < currentRecords.length; index++) {
                let record_to_download = {};
                for(var colIndex = 0; colIndex < columns.length ; colIndex ++) {
                    record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
                }
                data_to_download.push(record_to_download)

            }
            this.setState({ dataToDownload: data_to_download }, () => {
                // click the CSVLink component to trigger the CSV download
                this.csvLink.link.click()
            })

        }

    formattedDate(){
        const today = new Date();
        const yr = today.getFullYear();
        const mth = today.getMonth()+1;
        const dy = today.getDay();

        return `${yr}-${mth}-${dy}`;

    }

    ichirfStatus(ichirfId){
        return ichirfId.substr(3,1);
    }

    async fetchParticipants() {
        try {

            const pList = await this.state.demogService.queryPatients({lastNames: '**'});

            const participantList = pList.map((resultRow) => {
                return {
                    status: this.ichirfStatus(resultRow.lastNames),
                    ...resultRow
                };});

            this.setState({participantList});
        } catch (e) {
            alert(e.message)
        }
    }

    async componentWillMount() {
        await this.fetchParticipants();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.partyId !== this.props.partyId) {
            this.fetchParticipants();
            this.setState ({ partyId: this.props.partyId})
        }
    }
  render() {
      const title = `Participants ${this.state.partyId}`;
      const tableStyle =  { fontSize: '14px'};
      return (
      <div>
          <Row gutter={4} style={ {marginTop: 8} }>
                <Col span={6}>
                    <Button onClick={this.download}  icon="download">
                        Download to CSV
                    </Button>
                </Col>
              <Col span={2}>

              </Col>
                <Col span={12}>
                    <h3>{title}</h3>
                </Col>
          </Row>
          <Row>
              <Col span={24}>
                  <CSVLink  ref={(r) => this.csvLink = r} data={this.state.dataToDownload} filename= {`${title} ${this.formattedDate()}.csv`} className="hidden" target="_blank"/>
                  <ReactTable
                      ref={(r) => this.reactTable = r}
                      className="-striped -highlight"
                      data= {this.state.participantList}
                      style = {tableStyle}
                      columns={[
                          {
                              Header: 'ICHIRF Id',
                              accessor: 'lastNames', // String-based value accessors!
                          },
                          {
                              Header: 'Status',
                              accessor: 'status',
                          },
                          {
                              Header: 'Gender',
                              accessor: 'gender',
                          },
                          {
                              Header: 'Date of Birth',
                              accessor: 'dateOfBirth',
                          },
                          {
                              Header: 'Party Id',
                              accessor: 'id', // String-based value accessors!
                          },
                      ]}
                      defaultPageSize={100}
                      defaultSorted={[{
                          id   : 'Status',
                          desc : false,
                      }]}

                      getTrProps={(state, rowInfo) => {
                          if (rowInfo && rowInfo.row) {
                              return {

                                  onClick: (e) => {
                                      console.log(rowInfo.row.id);
                                      this.setState({
                                          selected: rowInfo.index,
                                          subjectID : rowInfo.row.id,
                                          ichirfID : rowInfo.row.lastNames
                                      })
                                  },
                                  style: {
                                      background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                      color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                  }
                              }
                          }else{

                          }
                          return {}
                      }
                      }

                  />
              </Col>
          </Row>
      </div>

    );
  }
}

export default ParticipantList;
