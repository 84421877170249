import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { css } from 'react-emotion';
import { BeatLoader } from 'react-spinners';
import CDRService from "../../../services/cdr/CDRService";

class CompositionList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            compositions: [],
            cdrService: new CDRService(props.cdrName),
            loading: false
        };
    }

    async componentDidMount() {
        this.setState( {loading: true});
        const compositions = await this.state.cdrService.listCompositions(this.props.ehrId);
        this.setState({ compositions });
        this.setState( {loading: false});
        console.log('Got Compositions');
    }


    render() {
        const override =
            css`
            display: block;
            margin: 0 auto;
            border-color: red;`;

        const title = `CDR name: ${this.state.cdrService.cdr.name}`;
        return (
            <div className="CompositionsList">
                <h4>{ title }</h4>
                    <Table striped bordered condensed hover>
                        <thead>
                        <tr>
                            <th>Ehr Id</th>
                            <th>Composition Id</th>
                            <th>Composition Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.compositions.map(composition => (
                            <tr key={composition.compositionId}>
                                <td>{composition.ehrId}</td>
                                <td>{composition.compositionId}</td>
                                <td>{composition.compositionName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <div className='sweet-loading'>
                        <BeatLoader
                            className={override}
                            sizeunit={"px"}
                            size={25}
                            color={'#49bc67'}
                            loading={this.state.loading}
                        />
                    </div>
            </div>

        );
    }
}

export default CompositionList;
